import React, { useEffect, useRef, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import useRepeatableFields from './use-repeatable-fields'
import SelectInput from './select_input'
import Tooltip from '../Tooltip/Tooltip'
import { Button } from '../Button/Button'

const RepeatableField = ({ id, label, description, schema, ...props }) => {
  const refs = useRef([])

  const {
    addRule,
    changeValueHandler,
    fieldsAreEmpty,
    options,
    removeRule,
    resetRules,
    rules,
    values,
    removeRuleBool,
    setRemoveRuleBool,
  } = useRepeatableFields(id, refs, schema)

  const touched =
    !fieldsAreEmpty ||
    rules?.length > 1 ||
    (rules?.length === 1 && !rules[0]?.isDefault && !rules[0]?.isPlaceholder)

  return (
    <div>
      <div
        className={[
          'display-flex',
          'flex-align-center',
          'flex-justify',
          'flex-row',
          'flex-gap',
        ].join(' ')}
      >
        <h2
          className={[
            'display-flex',
            'flex-gap',
            'flex-row',
            'margin-bottom-0',
          ].join(' ')}
        >
          {label}{' '}
          {description && (
            <Tooltip
              content={description}
              delay={500}
              display="block"
              id={'#' + id + 'description'}
              position="topCenter"
            >
              <Button
                align="center"
                iconName="circle-questions"
                iconOnly
                iconSize="xs"
                reduced
                shape="pill"
                srText={`${label} details`}
                textOnly
                theme="base-dark"
              />
            </Tooltip>
          )}
        </h2>
        <Button disabled={!touched} onClick={resetRules} reduced textOnly thin>
          Reset fields
        </Button>
      </div>
      <div
        className={[
          'bg-base-lightest-opacity-50',
          'border-1px',
          'border-base-light',
          'display-flex',
          'flex-column',
          'flex-gap',
          'padding-1',
          'radius-md',
        ].join(' ')}
      >
        {rules?.map((rule, index) => {
          return (
            <div
              className={[
                'display-flex',
                'flex-justify',
                'flex-gap-sm',
                'flex-row',
              ].join(' ')}
              key={index}
            >
              <SelectInput
                // defaultSelected={[rule]}
                groupId={id}
                id={rule.id}
                index={index}
                onChange={changeValueHandler}
                options={options}
                ref={(elem) => (refs.current[index] = elem)}
                schema={schema}
                values={values}
                removeRuleBool={removeRuleBool}
                setRemoveRuleBool={setRemoveRuleBool}
              />
              <Button
                align="start"
                className="margin-top-neg-2px"
                disabled={!rules || (rules.length === 1 && fieldsAreEmpty)}
                iconName="trash"
                iconOnly
                iconPercent="80"
                iconSize="sm"
                onClick={() => {
                  removeRule(rule.id)
                }}
                outline
                reduced
                srText="Remove this condition"
                theme={
                  !rules || (rules.length === 1 && fieldsAreEmpty)
                    ? 'base'
                    : 'primary'
                }
              />
            </div>
          )
        })}
        <div className={['margin-right-auto'].join(' ')}>
          {/*selectableSchema.options.length > 0 && (*/}
          <Button
            data-testid={`nac-advanced-search--${id}-add-rule`}
            disabled={rules.length === schema.length}
            iconName="plus"
            iconSize="xs"
            onClick={() => addRule(id)}
            outline
            // reduced
            size="2xs"
            thin
          >
            Add Condition
          </Button>
        </div>
      </div>
    </div>
  )
}

RepeatableField.defaultProps = {}

RepeatableField.propTypes = {
  /**
   * The id to associate with the repeatable group of inputs within the greater form
   */
  id: PropTypes.string,
  /**
   * The label to display above all repeatable fields within the same group
   */
  label: PropTypes.string,
  /**
   * The description to display in the tooltip when hovered on the icon next to the label for repeatable fields within the same group
   */
  description: PropTypes.string,
  /**
   * Defines the options available for each instance within the repeatable fields
   * and subsequently their validation and input types
   */
  schema: PropTypes.array.isRequired,
}

export default RepeatableField
